import {Button, ButtonGroup, Field, Input} from '@marketgoo/components';
import {isValidEmail, required} from 'components/UI/Form/validators';
import {useIsWordPressPlugin} from 'services/data';
import queryString from 'query-string';
import React from 'react';
import {useForm} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {connect} from 'react-redux';

const Remember = ({url_for, login_url, user_domain, initialValues}) => {
  const isWpPlugin = useIsWordPressPlugin();
  const {t} = useTranslation();
  const params = queryString.parse(location.search);
  const domain = (params && params.domain) || user_domain;
  const destination = isWpPlugin
    ? `${login_url}/wordpress` + (domain ? `?domain=${domain}` : '')
    : login_url;

  const {
    register,
    handleSubmit,
    formState: {errors, isSubmitting}
  } = useForm({defaultValues: initialValues});

  const onSubmit = () => {
    document.getElementById('form_remember').submit();
  };

  const closeWindow = () => {
    window.close();
  };

  return (
    <section id="remember-container">
      <section className="ola_ly-pageCenter">
        <header>
          <h2 className="ola-font-5-medium ola-nomargin">
            {t('remember.header.title')}
          </h2>
          <p className="ola-font-2-regular ola-color-neutral-700">
            <Trans
              i18nKey="remember.header.intro"
              components={[<a href={destination} key={0} />]}
            />
          </p>
        </header>
        <form
          id="form_remember"
          action={url_for}
          method="post"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="inputs-block">
            <div>
              <Field
                id="email"
                label={t('g.email')}
                error={!!errors.email}
                description={errors && errors.email && errors.email.message}
              >
                <Input
                  type="email"
                  placeholder={t('login.form.email.placeholder')}
                  {...register('email', {
                    required,
                    validate: isValidEmail
                  })}
                />
              </Field>
              {domain && <input type="hidden" value={domain} name="domain" />}
            </div>
          </div>
          <div className="actions">
            <ButtonGroup className="ola-margin-top-3 ola-margin-bottom-3">
              {isWpPlugin && window.opener && (
                <Button variant="secondary" type="button" onClick={closeWindow}>
                  {t('remember.cancel.button')}
                </Button>
              )}
              <Button
                variant="primary"
                busy={isSubmitting && t('g.please.wait')}
              >
                {t('remember.submit.button')}
              </Button>
            </ButtonGroup>
          </div>
        </form>
      </section>
    </section>
  );
};

const mapState = (_state, {user_email}) => ({
  initialValues: {
    email: user_email
  }
});

export default connect(mapState)(Remember);
