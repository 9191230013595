import useHydrate from 'services/data/useHydrate';

const useImagePath = () => {
  const {assets_path: assetsPath} = useHydrate('config');

  /**
   * @param {string} [type] - El tipo de imagen ('icons', 'illustrations', etc.).
   * @returns {function} - Una función que toma el nombre de la imagen y devuelve su ruta.
   */
  const getImagePath = (type) => (name) => {
    const paths = {
      icons: `${assetsPath}/img/icons/${name}.svg`,
      illustrations: `${assetsPath}/illustrations/${name}.svg`
    };

    return paths[type] || `${assetsPath}/img/${name}.svg`;
  };

  const getIconsPath = getImagePath('icons');
  const getIllustrationsPath = getImagePath('illustrations');

  return {getImagePath, getIconsPath, getIllustrationsPath};
};

export default useImagePath;
