import React from 'react';
import {useIsWordPressPlugin} from 'services/data';
import {useTranslation} from 'react-i18next';
import {Button} from '@marketgoo/components';
import queryString from 'query-string';

const RememberSent = ({login_url}) => {
  const isWpPlugin = useIsWordPressPlugin();
  const {t} = useTranslation();
  const params = queryString.parse(location.search);
  const domain = params && params.domain;
  const __goToLogin = () =>
    (window.location = isWpPlugin
      ? `${login_url}/wordpress` + (domain ? `?domain=${domain}` : '')
      : login_url);
  return (
    <section id="remember-container">
      <section className="ola_ly-pageCenter">
        <header>
          <h2 className="ola-font-5-medium ola-nomargin ola-center">
            {t('remember.sent.header.title')}
          </h2>
          <p className="ola-font-2-regular ola-center">
            {t('remember.sent.header.intro')}
          </p>
        </header>
        <div className="ola-center">
          <Button variant="primary" onClick={__goToLogin}>
            {t('remember.sent.button')}
          </Button>
        </div>
      </section>
    </section>
  );
};

export default RememberSent;
