import {Navigation} from '@marketgoo/components';
import MyWebsites from 'components/Header/MyWebsites';
import config from 'lib/application-config';
import React from 'react';
import {connect} from 'react-redux';
import {assetsPath, dealerName} from 'selectors';

const Header = ({assetsPath, dealerName}) => (
  <Navigation.Bar
    direction="horizontal"
    left={
      <>
        <a
          href={config.dealer.menu_logo_link || '/'}
          className="logo is-wp-plugin"
        >
          <img src={`${assetsPath}/img/logo-app.svg`} alt={dealerName} />
        </a>
        <MyWebsites />
      </>
    }
  />
);

const mapStateToProps = (store) => ({
  dealerName: dealerName(store),
  assetsPath: assetsPath(store)
});

export default connect(mapStateToProps)(Header);
