import {Modal, ModalContent, Typography} from '@marketgoo/components';
import {Illustration} from 'components/Image';
import React from 'react';
import {useTranslation} from 'react-i18next';

const OfsSuccessScore = ({closeModal}) => {
  const {t} = useTranslation();

  return (
    <Modal
      open
      data-cy="optimize-success-modal"
      variant="center"
      onClose={closeModal}
    >
      <ModalContent className="ola-center">
        <Illustration name="oyp/web-perfect" />
        <Typography as="h2" m={{t: 6}}>
          {t('optimize.successmodal.title')}
        </Typography>
        <Typography m={{t: 1}}>{t('optimize.successmodal.text')}</Typography>
      </ModalContent>
    </Modal>
  );
};

export default OfsSuccessScore;
