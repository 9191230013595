import useImagePath from 'services/data/useImagePath';

const Image = ({name, type, ...props}) => {
  const {getImagePath} = useImagePath();

  try {
    const src = getImagePath(type)(name);
    return <img src={src} {...props} />;
  } catch (error) {
    console.error(error.message);
    // Opción: usar una imagen por defecto si ocurre un error
    // return <img src="/path/to/default-image.svg" alt="Default" {...props} />;
  }
};

export {Illustration} from './Illustration';

export default Image;
