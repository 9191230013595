import {useMemo} from 'react';

function decode(string) {
  try {
    return JSON.parse(decodeURIComponent(string));
  } catch (e) {
    console.error('Error decoding JSON content:', e);
    return undefined;
  }
}

function useHydrate(variable) {
  return useMemo(() => {
    const metaElement = document.head.querySelector(`[name='${variable}']`);

    if (metaElement) {
      return decode(metaElement.getAttribute('content'));
    }

    return undefined;
  }, [variable]);
}

export default useHydrate;
