import {Button, Modal, ModalContent} from '@marketgoo/components';
import {ProductDescription} from 'components/Modals/Upgrade/ProductDescription';
import {modalHide} from 'components/UI/Modal/actions';
import {upgradeClick} from 'lib/redux/actions/upgrade';
import {getThemesPath} from 'selectors';
import {useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {sendEventOnClose, sendEventOnOpen, sendEventOnUpgrade} from './common';
import {Illustration} from 'components/Image';

/* Producto de origen:  Start || Evolution */
const ProductFrom = ({product, cta_key, btnClick}) => {
  const features = product?.features;
  const hasInstructionsToShow = features?.show_wp_instructions;
  const productType = hasInstructionsToShow ? 'to' : 'from';
  const {t} = useTranslation();
  return (
    <div className="product product-from">
      <Illustration
        name={
          hasInstructionsToShow ? 'upgrade/product-to' : 'upgrade/product-from'
        }
      />
      <div className="product-title">
        <h2 className="ola-font-5-medium">{product?.name}</h2>
        <span className="product-status">{t('modal.upgrade.basic.intro')}</span>
      </div>
      <div className="product-description">
        <ProductDescription
          features={features}
          product={productType}
          cta_key={cta_key}
        />
      </div>
      <div className="product-action">
        <Button variant="secondary" onClick={btnClick}>
          {t('modal.upgrade.basic.cta')}
        </Button>
      </div>
    </div>
  );
};

/* Producto de destino: Evolution ||  WordPress */
const ProductTo = ({product, cta_key, btnClick, hasWpUpgrade}) => {
  const features = product?.features;
  const product_name = product?.name;
  const themesPath = useSelector(getThemesPath);
  const route = `${themesPath}/illustrations/upgrade/`;
  const {t} = useTranslation();

  const WordpressDescription = () => (
    <div className="product-description">
      <ul>
        <li
          dangerouslySetInnerHTML={{
            __html: __('Get full website scans <strong> daily </strong>')
          }}
        ></li>
        <li
          dangerouslySetInnerHTML={{
            __html: __(
              'Compare your results with <strong> 4 competitors </strong>'
            )
          }}
        ></li>
        <li
          dangerouslySetInnerHTML={{
            __html: __(
              '<strong> Optimize your pages </strong> directly in the WP Editor'
            )
          }}
        ></li>
        <li
          dangerouslySetInnerHTML={{
            __html: __('Complete tasks <strong> automatically </strong>')
          }}
        ></li>
        <li
          dangerouslySetInnerHTML={{
            __html: __('Access expanded <strong> analytics report </strong>')
          }}
        ></li>
      </ul>
    </div>
  );

  return (
    <div className="product product-to">
      {hasWpUpgrade ? (
        <img className="imgWp" src={'/img/wizard/wizard-welcome-wp.svg'} />
      ) : (
        <Illustration name={'upgrade/product-to'} />
      )}
      <div className="product-title">
        <h2 className="ola-font-5-medium">{product_name}</h2>

        <span className="product-status"> {t('modal.upgrade.pro.intro')}</span>
      </div>
      {hasWpUpgrade ? (
        <WordpressDescription />
      ) : (
        <div className="product-description">
          <ProductDescription
            features={features}
            product="to"
            cta_key={cta_key}
          />
        </div>
      )}
      <div className="product-action">
        <Button variant="pro" onClick={btnClick}>
          {t('modal.upgrade.pro.cta')}
        </Button>
      </div>
    </div>
  );
};

/* Modal */
const Upgrade = ({
  closeModal,
  upgradeHandle,
  cta,
  product_from,
  product_to,
  target_url,
  hasWpUpgrade,
  closable = true,
  cbOnCloseModal = () => {}
}) => {
  const location = useLocation();

  const handleOnClose = () => {
    sendEventOnClose(location.pathname, cta?.key);
    cbOnCloseModal();
    closeModal();
  };

  const handleOnUpgradePlan = () => {
    sendEventOnUpgrade(location.pathname, cta?.key);
    upgradeHandle({
      relocate: target_url,
      cta: cta?.key
    });
  };

  useEffect(() => {
    sendEventOnOpen(location.pathname, cta?.key);
  }, []);

  return (
    <Modal
      className="upgrade-modal"
      open
      onClose={handleOnClose}
      closable={closable}
    >
      <ModalContent className="upgrade">
        <div className="modal-section active">
          <ProductFrom
            product={product_from}
            cta_key={cta?.key}
            btnClick={handleOnClose}
          />
        </div>
        <div className="modal-section">
          <ProductTo
            product={product_to}
            product_from={product_from}
            cta_key={cta?.key}
            hasWpUpgrade={hasWpUpgrade}
            btnClick={handleOnUpgradePlan}
          />
        </div>
      </ModalContent>
    </Modal>
  );
};

const mapStateToProps = ({data: {config}}, {type}) => {
  const cta = config?.dealer?.ctas?.[type || 'ribbon'];
  return {
    product_from: config.dealer?.products?.[cta?.product_from],
    product_to: config.dealer?.products?.[cta?.product_to],
    target_url: cta?.target_url,
    cta_key: 'upsell-' + cta?.key,
    cta,
    hasWpUpgrade: config.dealer?.ctas['wordpress-upgrade'] || null
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => {
    dispatch(modalHide());
  },
  upgradeHandle: (params) => {
    dispatch(upgradeClick(params));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Upgrade);
